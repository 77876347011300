import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";
import { redirect } from "react-router";
import { useFetcher, useLocation } from "react-router";
import Alert from "~/components/alert";
import ButtonLink from "~/components/button-link";
import Panel from "~/components/panel";
import { RemixForm } from "~/components/remix-form";
import { Buttons } from "~/components/remix-form/buttons";
import { Errors } from "~/components/remix-form/errors";
import { handleError } from "~/components/remix-form/errors.server";
import { Input } from "~/components/remix-form/input";
import { loginUser } from "~/models/user.server";
import {
  commitSession,
  getOptionalUserId,
  getSession
} from "~/sessions.server";
import type { Route } from "./+types/route";

export const loader = async ({ request }: Route.LoaderArgs) => {
  const userId = await getOptionalUserId(request);

  if (userId) {
    throw redirect("/");
  }

  return null;
};

export const action = async ({ request }: Route.ActionArgs) => {
  try {
    const fd = await request.formData();
    const data = await (fd.get("action") === "password-signin"
      ? loginUser(
          {
            email: fd.get("email") as string,
            password: fd.get("password") as string
          },
          request
        )
      : loginUser({ token: fd.get("credential") as string }, request));
    const session = await getSession(request.headers.get("Cookie"));
    session.set("token", data.login.token);
    session.set("id", data.login.user.id);
    session.set("email", data.login.user.email);
    const result = await commitSession(session);

    throw redirect("/", { headers: { "Set-Cookie": result } });
  } catch (error) {
    return handleError(error);
  }
};

export const meta = () => [{ title: "PFCS Intranet: Login" }];

export default function Login() {
  const location = useLocation();
  const locationState = location.state as { message?: string };
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const fetcher = useFetcher<any>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="flex justify-center py-16">
      <Panel mode="success" className="w-[400px]">
        <Panel.Header title="Sign In" />
        <Panel.Body>
          <GoogleOAuthProvider clientId="472401538217-ip5rh1brg0cv61rg9d9ngmvjr1jf14lf.apps.googleusercontent.com">
            <div className="flex h-16 justify-center">
              <GoogleLogin
                text="signin"
                size="large"
                onSuccess={(response) => {
                  const fd = new FormData();
                  fd.append("credential", response.credential || "");
                  // Specifically post to /login, as this can sometimes trigger after
                  // already being logged in, possibly if they tap login multiple times?
                  fetcher.submit(fd, { action: "/login", method: "post" });
                }}
                onError={() => {
                  window.alert("Login Failed");
                }}
                useOneTap
              />
            </div>
          </GoogleOAuthProvider>

          {fetcher.data?.errors && (
            <Errors className="mt-4" errors={fetcher.data.errors} />
          )}

          {locationState?.message && (
            <Alert mode="danger" className="mt-4">
              {locationState.message}
            </Alert>
          )}

          <div>
            <hr />
            <RemixForm redirect prompt={false}>
              <p className="help-block">
                If you have a Pete Fowler Construction email, please use the
                Sign In with Google button above.
              </p>
              <Errors />
              <input type="hidden" name="action" value="password-signin" />
              <Input
                value={email}
                allowPasswordManagers
                onChange={(e) => setEmail(e.target.value)}
                name="email"
              />
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                allowPasswordManagers
                name="password"
                type="password"
              />
              <Buttons
                primaryLabel="Sign In"
                savingLabel="Signing In..."
                disabled={!email || !password}
                rightContent={
                  <ButtonLink to="/reset-password">Forgot Password?</ButtonLink>
                }
              />
            </RemixForm>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
}
